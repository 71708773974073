import { API_URL } from "Settings";
import axios from "axios";
const PAR_PASSWORD_LENGTH = 'PASSWORD_LENGTH';
const handleRecovery = async (user) => {
    try {

      
     const response = await axios.post(
      `${API_URL}/auth/recovery`,
      user
    ); 

  
    return response;
    
    } catch (error) {
      
      return error;
    }
};

const getToken = async (id) => {
  try {

    const datos = {
      resetToken: id,
    };
   const response = await axios.post(
    `${API_URL}/auth/getRecoveryToken`,
    datos
  ); 
  return response;
  } catch (error) {
    //console.log({error});
  }
}

const handleChangepassword = async (user,token) => {
  try {
   const response = await axios.post(
    `${API_URL}/auth/newPassword`,
    user,{
      headers: {
        reset: token,
      },
    }
  ); 
  //console.log({response});
  return response;
  
  } catch (error) {
    return error;
  }
};

// validar la contraseña, debe cumplir con los siguientes requisitos:, Tener al menos 6 caracteres, Tener al menos una letra mayúscula, Tener al menos una letra minúscula, Tener al menos un número, Tener al menos un caracter especial

const validatePassword = async (password) => {
  const parametros = await axios.get(`${API_URL}/parameters`);
  const valor_parametro = parametros.data.find((parametro) => parametro.parameter_id === PAR_PASSWORD_LENGTH);  

  let specialChars = "!@#$%^&*";
  let regex = new RegExp(`^(?=.*[0-9])(?=.*[${specialChars}])(?=.*[a-z])(?=.*[A-Z]).{${valor_parametro.parametervalue},}$`);

  return regex.test(password);
}


export { handleRecovery,getToken,handleChangepassword,validatePassword};