import { useQuery, useMutation, useQueryClient } from "react-query";
import * as servicioServices from "../services/servicioServices";
import { CACHE_TIME, STATE_TIME } from "../../../Settings";
export const useServicio = () =>
  useQuery("servicio", servicioServices.fetchServicio, {
    staleTime: STATE_TIME,
    cacheTime: CACHE_TIME,
  });

export const useCreateServicio = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, data, isSuccess } = useMutation(
    servicioServices.createServicio,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("servicio");
        //console.log("Create successful:", data);
      },
    }
  );
  return {
    createServicio: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    servicio: data, // Respuesta de la API tras una creación exitosa
    isSuccess, // Estado de éxito de la mutación
  };
};

export const useUpdateServicio = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    servicioServices.updateServicio,
    {
      onSuccess: () => {
        // Invalidate and refetch data to get updated list
        queryClient.invalidateQueries("servicio");
        //console.log("Update successful");
      },
    }
  );

  return {
    updateServicio: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    isSuccess, // Estado de éxito de la mutación
  };
};
export const useDeleteServicio = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    servicioServices.deleteServicio,
    {
      onSuccess: () => {
        // Invalidate and refetch data to update the list
        queryClient.invalidateQueries("servicio");
        //console.log("Delete successful");
      },
    }
  );

  return {
    deleteServicio: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    isSuccess, // Estado de éxito de la mutación
  };
};
