import { useQuery, useMutation, useQueryClient } from "react-query";
import * as ordenesServices from "../services/ordenesServices";
import { CACHE_TIME, STATE_TIME } from "../../../Settings";
export const useOrdenes = () =>
  useQuery("ordenes", ordenesServices.fetchOrdenes, {
    staleTime: STATE_TIME,
    cacheTime: CACHE_TIME,
  });

export const useCreateOrden = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, data, isSuccess } = useMutation(
    ordenesServices.createOrden,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("ordenes");
        // console.log("Create successful:", data);
      },
    }
  );
  return {
    createOrden: mutate,
    isLoading,
    isError,
    error,
    orden: data,
    isSuccess,
  };
};

export const useUpdateOrden = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    ordenesServices.updateOrden,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("ordenes");
        // console.log("Update successful");
      },
    }
  );

  return {
    updateOrden: mutate,
    isLoading,
    isError,
    error,
    isSuccess,
  };
};

export const useDeleteOrden = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    ordenesServices.deleteOrden,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("ordenes");
        // console.log("Delete successful");
      },
    }
  );

  return {
    deleteOrden: mutate,
    isLoading,
    isError,
    error,
    isSuccess,
  };
};
