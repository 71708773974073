// import { z } from "zod";
import  { instance, instanceWithoutInterceptors } from "../api/axios";

export function api({
  type = "private",
  method,
  path,
  requestSchema,
  responseSchema,
}) {
  return async (requestData) => {
    // Validate request data
    // requestSchema.parse(requestData);

    // Prepare API call
    let url = path;
    let data = null;

    if (requestData) {
      if (method === "GET" || method === "DELETE") {
        url += `${requestData}`;
      } else {
        data = requestData;
      }
    }

    const config = {
      method,
      url,
      data,
    };

    // Make API call based on the type of request
    //console.log('antes dekl desastre', {config,type})
    const response =
      type === "private"
        ? await instance(config)
        : await instanceWithoutInterceptors(config);

    // Parse and validate response data
    //console.log({ response })
    return response.data;

    // const result = responseSchema.safeParse(response.data);

    // if (!result.success) {
    //   console.error("🚨 Safe-Parsing Failed ", result.error);
    //   throw new Error(result.error.message);
    // } else {
    //   return result.data;
    // }
  };
}
