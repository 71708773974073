import { useQuery, useMutation, useQueryClient } from "react-query";
import * as niterosService from "../services/niterosService";
import { CACHE_TIME, STATE_TIME } from "../../../Settings";
export const useNiteros = () =>
  useQuery("niteros", niterosService.fetchNiteros, {
    staleTime: STATE_TIME,
    cacheTime: CACHE_TIME,
  });

export const useCreateNiteros = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, data, isSuccess } = useMutation(
    niterosService.createNitero,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("niteros");
        // console.log("Create successful:", data);
      },
    }
  );
  return {
    createNitero: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    nitero: data, // Respuesta de la API tras una creación exitosa
    isSuccess, // Estado de éxito de la mutación
  };
};

export const useUpdateNiteros = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    niterosService.updateNitero,
    {
      onSuccess: () => {
        // Invalidate and refetch data to get updated list
        queryClient.invalidateQueries("niteros");
        // console.log("Update successful");
      },
    }
  );

  return {
    updateNitero: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    isSuccess, // Estado de éxito de la mutación
  };
};

export const useDeleteNiteros = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    niterosService.deleteNitero,
    {
      onSuccess: () => {
        // Invalidate and refetch data to update the list
        queryClient.invalidateQueries("niteros");
        // console.log("Delete successful");
      },
    }
  );

  return {
    deleteNitero: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    isSuccess, // Estado de éxito de la mutación
  };
};
