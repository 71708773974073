/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
// import { UserContextProvider } from "./context/UserContext";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import PrivateRoute from "./features/components/PrivateRoute";
import { QueryClient, QueryClientProvider } from "react-query";

import { ReactQueryDevtools } from "react-query/devtools";
import { ChakraProvider } from "@chakra-ui/react";
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools />
    <ChakraProvider>
      {/* <UserContextProvider> */}
        <HashRouter>
          <Switch>
            <PrivateRoute
              path="/admin"
              roles={[
                "superadmin",
                "developer",
                "administrator",
                "coordinator",
              ]}
              component={AdminLayout}
            />
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Redirect from="*" to="/admin/index" />
          </Switch>
        </HashRouter>
      {/* </UserContextProvider> */}
    </ChakraProvider>
  </QueryClientProvider>
);
