// import { z } from "zod";
// import { AxiosError } from "axios";
import { useUserStore } from "../../../store/useUserStore";
import { useMutation } from "react-query";
import { SignInAPI } from "../services/authServices";
// import { SignInAPI } from "@/pages/sign-in/api/query-slice";
// import { SignInAPIResponseSchema, SignInFormType } from "@/pages/sign-in/schema";
// import { toast } from "sonner";

export const useSignIn = () => {
  const { setCredentials } = useUserStore();
  const { mutate, isLoading } = useMutation(SignInAPI.signIn, {
    onSuccess: (data) => {
      setCredentials(data);
    },
    onError: (error) => {
      // toast.error(error.message);
      // console.log({ error });
    },
  });

  return {
    signIn: mutate,
    isLoading,
  };
};
