import React, { Suspense } from "react";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Container, Spinner } from "reactstrap";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import routes from "../routes.js";
import { useUserStore } from "store/useUserStore.js";

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { user: isAuthenticated } = useUserStore();
  const userRoles = isAuthenticated?.data?.user?.role || [];

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && roles.some((role) => userRoles.includes(role)) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/admin/index", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        const RouteComponent = prop.private ? PrivateRoute : Route;
        return (
          <RouteComponent
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            roles={prop.roles}
          />
        );
      }
      return null;
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return <h2 style={{ color: "white" }}>{routes[i].name}</h2>;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/logoht.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h3 style={{ color: "#007bff" }}>
                  <Spinner color="primary" />
                </h3>
              </div>
            </div>
          }
        >
          <AdminNavbar
            {...props}
            brandText={getBrandText(props.location.pathname)}
          />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/notfound" />
          </Switch>
        </Suspense>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
