import React, { useState } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { Redirect, useHistory } from "react-router-dom";
import "../../app.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useSignIn } from "../../features/auth/hooks/useSignIn";
import { useToast } from "@chakra-ui/react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: ["com", "net", "org", "edu"] } })
    .required()
    .label("Email")
    .messages({
      "string.email": "El email debe ser válido",
      "string.empty": "El email es requerido",
      "any.required": "El email es requerido",
    }),
  password: Joi.string().min(5).required().label("Password").messages({
    "string.min": "La contraseña debe tener al menos 5 caracteres",
    "string.empty": "La contraseña es requerida",
    "any.required": "La contraseña es requerida",
  }),
});

const Login = () => {
  const toast = useToast();

  let navigation = useHistory();
  const { signIn, isLoading } = useSignIn();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
  });

  const gotorecovery = () => {
    navigation.push("/auth/recovery");
  };

  const handleLogin = async (user) => {
    try {
      signIn(user, {
        onSuccess: (response) => {
          //console.log({ response });
          toast({
            title: "Inicio de sesión exitoso",
            description: `Bienvenido ${response.data.user.usnom} ${response.data.user.usapell}`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
            colorScheme: "blue",
          });

          navigation.push("/admin/index");
        },
        onError: (error) => {
          toast({
            title: "Error al iniciar sesión",
            description: error?.response?.data?.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          //console.log({ error });
        },
      });
    } catch (error) {
      // console.error("Error logging in", error);
      return error;
    }
  };

  const submit = async (data) => {
    try {
      await handleLogin(data);
    } catch (error) {
      // console.error("Login error", error);
    }
  };

  const onError = (err) => {
    //console.log({ err });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setTimeout(() => {
      setShowPassword(false);
    }, 5000);
  };

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent pb-3">
          <h1
            style={{
              textAlign: "center",
              lineHeight: "80%",
              fontWeight: "bold",
            }}
          >
            Inicio de sesión
          </h1>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          {/* <div className="text-center text-muted mb-4"> */}
            {/* <small>Iniciar sesión con credenciales</small> */}
          {/* </div> */}
          <Form onSubmit={handleSubmit(submit, onError)}>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="email"
                      placeholder="Correo electrónico"
                      autoComplete="email"
                    />
                  )}
                />
              </InputGroup>
              {errors.email && (
                <div className="text-danger">{errors.email.message}</div>
              )}
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type={showPassword ? "text" : "password"}
                      placeholder="Contraseña"
                    />
                  )}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? <IoMdEye /> : <IoMdEyeOff />}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {errors.password && (
                <div className="text-danger">{errors.password.message}</div>
              )}
            </FormGroup>
            <div className="text-center">
              <Button
                className="my-4 custom-gradient rounded-pill"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Iniciando..." : "Iniciar sesión"}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      <Row className="mt-3">
        <Col xs="6">
          <span
            className="text-bold"
            style={{ cursor: "pointer", color: "#ffff" }}
            onClick={gotorecovery}
          >
            <small>Olvide mi contraseña?</small>
          </span>
        </Col>
      </Row>
    </Col>
  );
};

export default Login;
