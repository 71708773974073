import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useUserStore } from "store/useUserStore";

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { user: isAuthenticated } = useUserStore();
  const userRoles = isAuthenticated?.data?.user?.role || [];

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Redirect to="/auth/login" />;
        }
        if (!roles.some((role) => userRoles.includes(role))) {
          return <Redirect to="/admin/index" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
