import { useState } from "react";

import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { handleRecovery } from "services/recoveryPassword";
import "../../app.css";
import { useToast } from "@chakra-ui/react";

const Recovery = () => {
  let navigation = useHistory();
  const [user, setUser] = useState({
    email: "",
    auth: 0,
  });
  const [ocultar, setOcultar] = useState(true);

  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState(false);
  const Toast = useToast();
  const submit = async (e) => {
    try {
      e.preventDefault();
      setCargando(true);
      setError(null);
      const logiad = await handleRecovery(user);

      if (logiad.data?.message === "Error sending mail") {
        Toast({
          title: "Error",
          description:
            "Error al enviar el correo, Comuniquese con el administrador",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });

        setTimeout(() => {
          navigation.push("/auth/Login");
        }, 2000);
        return;
      }
      if (logiad.response?.status === 401) {
        Toast({
          title: "Error",
          description:
            logiad.response?.data.message || "Error de autenticacion",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });

        return;
      }
      setOcultar(false);
    } catch (error) {
      setCargando(false);
      const messageError = error.response?.data.message ?? error.message;
      setError(messageError);
    } finally {
      setCargando(false);
    }
  };

  const goToLogin = () => {
    navigation.push("/auth/Login");
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-3">
            <h1 style={{ textAlign: "center", lineHeight: "80%" }}>
              Recuperar contraseña
            </h1>
          </CardHeader>
          {ocultar ? (
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>
                  Ingrese su dirección de correo electrónico a continuación y le
                  enviaremos un enlace para establecer una nueva contraseña.
                </small>
              </div>
              <Form onSubmit={submit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      autoComplete="email"
                      type="email"
                      onChange={(e) => {
                        setUser({
                          ...user,
                          email: e.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4 custom-gradient rounded-pill"
                    type="submit"
                    disabled={cargando}
                  >
                    {cargando ? (
                      <>
                        <Spinner size="sm" /> Enviando...
                      </>
                    ) : (
                      "Enviar"
                    )}
                  </Button>
                </div>
              </Form>
              {error && (
                <div className="alert alert-danger">
                  {" "}
                  <span style={{ textAlign: "center" }}>
                    {JSON.parse(JSON.stringify(error))}
                  </span>
                </div>
              )}
            </CardBody>
          ) : (
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>
                  El correo electronico fue enviado satisfactoriamente
                </small>
              </div>
            </CardBody>
          )}
        </Card>

        <Row className="mt-3">
          <Col xs="6">
            <span
              className="text-light"
              style={{ cursor: "pointer" }}
              onClick={goToLogin}
            >
              {<small>Volver</small>}
            </span>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              {/* <small>Create new account</small> */}
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Recovery;
