import { useQuery, useMutation, useQueryClient } from "react-query";
import * as actservperService from "../services/actservperServices";
import { CACHE_TIME, STATE_TIME } from "../../../Settings";
export const useActservper = () =>
  useQuery("actservper", actservperService.fetchActservper);

export const useActservperById = (id) => {
  return useQuery(
    ["actservper", id],
    () => actservperService.fetchActservperById(id),
    {
      enabled: !!id, // Solo ejecuta la consulta si el ID es no nulo
      staleTime: STATE_TIME, // Define cuánto tiempo la información es considerada fresca
      cacheTime: CACHE_TIME, // Define cuánto tiempo se mantiene la información en el caché
      onError: (error) => {
        // console.error("Error fetching actservper details:", error);
      },
    }
  );
};

export const useGroupClients = (id) => {
  return useQuery(
    ["actservper", id],
    () => actservperService.fetchGroupClients(id),
    {
      enabled: !!id,
      staleTime: STATE_TIME,
      cacheTime: CACHE_TIME,
      onError: (error) => {
        // console.error("Error fetching actservper details:", error);
      },
    }
  );
};
export const useConceptoById = (id) => {
  return useQuery(
    ["actservper", id],
    () => actservperService.fetchConceptosById(id),
    {
      enabled: !!id,
      staleTime: STATE_TIME,
      cacheTime: CACHE_TIME,
      onError: (error) => {
        // console.error("Error fetching actservper details:", error);
      },
    }
  );
};

export const useCreateActservper = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, data, isSuccess } = useMutation(
    actservperService.createActservper,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("actservper");
        // console.log("Create successful:", data);
      },
    }
  );
  return {
    createActservper: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    actservper: data, // Respuesta de la API tras una creación exitosa
    isSuccess, // Estado de éxito de la mutación
  };
};

export const useUpdateActservper = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    actservperService.updateActservper,
    {
      onSuccess: () => {
        // Invalidate and refetch data to get updated list
        queryClient.invalidateQueries("actservper");
        // console.log("Update successful");
      },
    }
  );

  return {
    updateActservper: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    isSuccess, // Estado de éxito de la mutación
  };
};

export const useDeleteActservper = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    actservperService.deleteActservper,
    {
      onSuccess: () => {
        // Invalidate and refetch data to update the list
        queryClient.invalidateQueries("actservper");
        // console.log("Delete successful");
      },
    }
  );

  return {
    deleteActservper: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    isSuccess, // Estado de éxito de la mutación
  };
};
