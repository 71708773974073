import { useQuery, useMutation, useQueryClient } from "react-query";
import * as unidadServices from "../services/unidadServices";
import { CACHE_TIME, STATE_TIME } from "../../../Settings";
export const useUnidad = () =>
  useQuery("unidad", unidadServices.fetchUnidades, {
    staleTime: STATE_TIME,
    cacheTime: CACHE_TIME,
  });

export const useCreateUnidad = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, data, isSuccess } = useMutation(
    unidadServices.createUnidad,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("unidad");
        //console.log("Create successful:", data);
      },
    }
  );
  return {
    createUnidad: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    unidad: data, // Respuesta de la API tras una creación exitosa
    isSuccess, // Estado de éxito de la mutación
  };
};
export const useUpdateUnidad = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    unidadServices.updateUnidad,
    {
      onSuccess: () => {
        // Invalidate and refetch data to get updated list
        queryClient.invalidateQueries("unidad");
        //console.log("Update successful");
      },
    }
  );
  return {
    updateUnidad: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    isSuccess, // Estado de éxito de la mutación
  };
};

export const useDeleteUnidad = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    unidadServices.deleteUnidad,
    {
      onSuccess: () => {
        // Invalidate and refetch data to update the list
        queryClient.invalidateQueries("unidad");
        //console.log("Delete successful");
      },
    }
  );
  return {
    deleteUnidad: mutate, // Función para invocar la mutación
    isLoading, // Estado de carga
    isError, // Indica si hay un error
    error, // Objeto de error
    isSuccess, // Estado de éxito de la mutación
  };
};
