
import { api } from "../utils/api";
import { API_ENDPOINT } from "../utils/endpoints-constant";


export const getRefreshToken = api({
  method: "POST",
  path: API_ENDPOINT.REFRESH_TOKEN,
//   requestSchema: RefreshTokenRequest,
//   responseSchema: RefreshTokenResponse,
  type: "public",
});
