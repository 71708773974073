import { API_URL } from 'Settings';
import axios from 'axios';
import { useUserStore } from "../store/useUserStore";
import { getRefreshToken } from '../services/getRefreshToken';

const baseConfig = {
  baseURL: `${API_URL}`,
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
};
export const instanceWithoutInterceptors = axios.create(baseConfig);

export const instance = axios.create(baseConfig);
instance.interceptors.request.use(
  function (config) {
    const accessToken = useUserStore.getState().user?.data?.accessToken;
    

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // console.log({error})

    if (
      error.response?.status === 401 &&
      originalRequest &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
    
        const refreshToken = useUserStore.getState().user?.data?.refreshToken || useUserStore.getState().user?.refreshToken ;
        const user = useUserStore.getState().user?.data?.user 
        // console.log({refreshToken})
        const response = await getRefreshToken({refreshToken});
        
        // console.log({response})

        const { payload } = response;
        // console.log({payload})

        useUserStore.setState({ user: { data:{accessToken: payload.accessToken, refreshToken ,user}} });

        originalRequest.headers.Authorization = `Bearer ${payload.accessToken}`;

        return instance(originalRequest);
      } catch (error) {
        if (error.response?.status === 403) {
          useUserStore.getState().removeCredentials();
          return;
        }
      }
    }

    // console.log({errors:'pello',error})
    return Promise.reject(error);
  }
);
// const axiosInstance = axios.create({
//   baseURL: `${API_URL}`,
//   headers: {
//     'Content-Type': 'application/json',
//     'Accept': 'application/json',
//     'Authorization': `Bearer ${localStorage.getItem('token')}`
//   }
// });
const axiosInstance = instance

export default axiosInstance;