import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Spinner } from "reactstrap";

const FullHeightContainer = styled(Container)`
  height: 100vh;
`;

const CenteredRow = styled(Row)`
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ErrorMessage = styled.div`
  background-color: white;
  padding: 2rem 3rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 80%;
`;

const ErrorTitle = styled.h2`
  color: #d32f2f;
  margin-bottom: 1rem;
`;

const ErrorText = styled.p`
  color: #333;
  font-size: 1.2rem;
`;

export const IsLoading = () => (
  <FullHeightContainer fluid>
    <CenteredRow>
      <Col xs="auto">
        <Spinner color="primary" />
      </Col>
    </CenteredRow>
  </FullHeightContainer>
);

export const IsError = () => (
  <FullHeightContainer fluid>
    <CenteredRow>
      <Col xs="auto">
        <ErrorMessage>
          <ErrorTitle>Error de conexión</ErrorTitle>
          <ErrorText>
            Lo sentimos, no se pudo establecer conexión con el servidor. Por
            favor, verifica tu conexión a internet e intenta nuevamente.
          </ErrorText>
        </ErrorMessage>
      </Col>
    </CenteredRow>
  </FullHeightContainer>
);
