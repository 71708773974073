import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import "../../app.css";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useUserStore } from "store/useUserStore";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const [activeCategory, setActiveCategory] = useState(null);
  const { user: isAuthenticated } = useUserStore();
  const userRoles = isAuthenticated?.data?.user?.role || [];
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const toggleCategory = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  const createLinks = (routes) => {
    const filteredRoutes = routes
      .filter((route) => {
        if (route.roles && route.roles.length > 0) {
          return route.roles.some((role) => userRoles?.includes(role));
        }
        return true;
      })
      .filter((route) => route.category);

    const categories = [
      ...new Set(filteredRoutes.map((route) => route.category)),
    ];

    return categories.map((category, index) => {
      const categoryIcon = filteredRoutes.find(
        (route) => route.category === category
      ).categoryIcon;

      return (
        <div key={index}>
          <NavItem>
            <NavLink
              href="#"
              onClick={() => toggleCategory(category)}
              className={`font-weight-bold d-flex align-items-center ${
                activeCategory === category ? "active-category" : ""
              }`}
            >
              <span
                style={{
                  marginRight: "10px",
                }}
              >
                {categoryIcon}
              </span>
              <span>{category}</span>
            </NavLink>
          </NavItem>
          <Collapse isOpen={activeCategory === category}>
            {filteredRoutes
              .filter((route) => route.category === category)
              .map((prop, key) => {
                return (
                  <NavItem key={key}>
                    <NavLink
                      to={prop.layout + prop.path}
                      tag={NavLinkRRD}
                      onClick={closeCollapse}
                      activeClassName="active-link"
                      className={`${activeRoute(prop.layout + prop.path)}`}
                    >
                      <span
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        {prop.icon}
                      </span>
                      {prop.name}
                    </NavLink>
                  </NavItem>
                );
              })}
          </Collapse>
        </div>
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand
            className="pt-0 d-flex justify-content-center "
            {...navbarBrandProps}
          >
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
              style={{ maxWidth: "200px", height: "auto" }}
            />
          </NavbarBrand>
        ) : null}

        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center"></Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">bienvenido!</h6>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                href="/#/auth/Login"
                onClick={() => {
                  localStorage.removeItem("user");
                }}
              >
                <i className="ni ni-user-run" />
                <span>Cerrar Sesión</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
