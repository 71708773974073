import { create } from "zustand";
import { persist } from "zustand/middleware";

const userStoreSlice = (set) => ({
  user: null,
  setCredentials: (user) => set({ user }),
  removeCredentials: () => set({ user: null }),
});

const persistedUserStore = persist(userStoreSlice, {
  name: "user",
  getStorage: () => sessionStorage,
});

export const useUserStore = create(persistedUserStore);
