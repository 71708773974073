import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { getToken, handleChangepassword } from "services/recoveryPassword";
import Swal from "sweetalert2";
import Joi from "joi";
import "../../app.css";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const NewPassword = () => {
  const [token, setToken] = useState({});
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState({
    password: false,
    passwordconfirm: false,
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
    setTimeout(() => {
      setShowPassword({
        password: false,
        passwordconfirm: false,
      });
    }, 5000);
  };

  const history = useHistory();

  const initialState = {
    password: "",
    confirmpass: "",
  };
  const [pass, setPass] = useState(initialState);

  const passwordSchema = Joi.object({
    password: Joi.string()
      .min(8)
      .max(30)
      .pattern(
        new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
        )
      )
      .required()
      .messages({
        "string.min": "La contraseña debe tener al menos 8 caracteres",
        "string.max": "La contraseña no puede exceder los 30 caracteres",
        "string.pattern.base":
          "La contraseña debe tener una letra mayúscula, una letra minúscula, un número y un caracter especial",
        "string.empty": "La contraseña es requerida",
      }),
    confirmpass: Joi.any().equal(Joi.ref("password")).required().messages({
      "any.only": "Las contraseñas no coinciden",
    }),
  });

  const gotorecovery = () => {
    history.push("/auth/recovery");
  };

  useEffect(() => {
    const urlActual = window.location.href;
    const regex = /id=([^&]+)/;
    const resultado = urlActual.match(regex);
    const id = resultado ? resultado[1] : null;

    const search = async () => {
      try {
        const setoken = await getToken(id);
        setToken(setoken.data);
      } catch (error) {
        // console.error(error);
      }
    };
    search();
  }, []);

  const submit = async (e) => {
    e.preventDefault();

    const validationResult = passwordSchema.validate(pass, {
      abortEarly: false,
    });
    if (validationResult.error) {
      const errors = validationResult.error.details.map(
        (detail) => detail.message
      );
      setError(errors.join(", "));
      return;
    }

    try {
      setCargando(true);
      setError(null);

      const datos = {
        newPassword: pass.password,
      };

      const logiad = await handleChangepassword(datos, token.recoveryToken);
      if (logiad.response?.data.message === "jwt expired") {
        throw new Error("El enlace ha expirado");
      }
      if (logiad.response?.status === 401) {
        throw new Error(logiad.response?.data.message);
      }

      setPass(initialState);
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Cambio de contraseña exitoso!",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      setTimeout(() => {
        setError(null);
        history.push("/auth/Login");
      }, 3000);
    } catch (error) {
      const messageError = error.response?.data.message ?? error.message;
      setError(messageError);
    } finally {
      setCargando(false);
    }
  };

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent pb-3">
          <h1 style={{ textAlign: "center", lineHeight: "80%" }}>
            Cambiar contraseña
          </h1>
        </CardHeader>
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-4">
            <small>Ingresa tu nueva contraseña</small>
          </div>
          <Form onSubmit={submit}>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Contraseña"
                  type={showPassword.password ? "text" : "password"}
                  value={pass.password}
                  onChange={(e) =>
                    setPass({ ...pass, password: e.target.value })
                  }
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    onClick={() => togglePasswordVisibility("password")}
                  >
                    {showPassword.password ? <IoMdEyeOff /> : <IoMdEye />}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Confirmar Contraseña"
                  type={showPassword.passwordconfirm ? "text" : "password"}
                  value={pass.confirmpass}
                  onChange={(e) =>
                    setPass({ ...pass, confirmpass: e.target.value })
                  }
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    onClick={() => togglePasswordVisibility("passwordconfirm")}
                  >
                    {showPassword.passwordconfirm ? (
                      <IoMdEyeOff />
                    ) : (
                      <IoMdEye />
                    )}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            {error && (
              <div
                className="error-message"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <span style={{ color: "red", textAlign: "center" }}>
                  {error}
                </span>
              </div>
            )}

            <div className="text-center">
              <Button
                className="custom-gradient rounded-pill my-4"
                disabled={cargando}
              >
                Confirmar
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      <Row className="mt-3">
        <Col xs="6">
          <span
            className="text-light"
            style={{ cursor: "pointer" }}
            onClick={gotorecovery}
          ></span>
        </Col>
      </Row>
    </Col>
  );
};

export default NewPassword;
