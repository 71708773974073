/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components

import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import "../../app.css";
import { useStatistics } from "../../features";

const Header = () => {
  const { data: dataStatistics } = useStatistics();

 // console.log(dataStatistics?.data?.clientes);
  return (
    <>
      <div className="header custom-gradient pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Link to="/admin/actividad">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Actividad
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {dataStatistics?.data.movimientos}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          {/* <i className="fa fa-arrow-up" /> 3.48% */}
                        </span>{" "}
                      </p>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

              {dataStatistics?.data.clientes > 0 ? (
                <Col lg="6" xl="3">
                  <Link to="/admin/cliente">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Cliente
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {dataStatistics?.data.clientes}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fas fa-chart-pie" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-danger mr-2">
                            {/* <i className="fas fa-arrow-down" /> 3.48% */}
                          </span>{" "}
                        </p>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              ) : (
                <Col lg="6" xl="3">
                  <Link to="/admin/unidad">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Unidad
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {dataStatistics?.data.unidad}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fas fa-chart-pie" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-danger mr-2">
                            {/* <i className="fas fa-arrow-down" /> 3.48% */}
                          </span>{" "}
                        </p>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              )}

              <Col lg="6" xl="3">
                <Link to={"/admin/orden"}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Ordenes
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {dataStatistics?.data.ordenes}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          {/* <i className="fas fa-arrow-down" /> 1.10% */}
                        </span>{" "}
                      </p>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col lg="6" xl="3">
                <Link to={"/admin/servicio"}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Servicios
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {dataStatistics?.data.servicios}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          {/* <i className="fas fa-arrow-up" /> 12% */}
                        </span>{" "}
                        {/* <span className="text-nowrap">Desde el ultimo mes</span> */}
                      </p>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
