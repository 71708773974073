export const API_URL = process.env.REACT_APP_API_URL;
export const CACHE_TIME = process.env.REACT_APP_CACHE_VALUE;
export const STATE_TIME = process.env.REACT_APP_STATE_TIME;
export const initialRows = 7;
export const columnFormats = {
  Id: "text-center",
  ID: "text-center",
  Accion: "text-center",
  Estado: "text-center",
  Mes: "text-center",
  Año: "text-center",
  Nit: "text-right",
  Honorario: "text-right",
  Costo: "text-right",
  "Valor Nube": "text-right",
  Cantidad: "text-right",
  "Valor Faltante": "text-right",
  Ingreso: "text-right",
};
export const PAGE_SIZES = [7, 10, 25, 50];
