import React, { useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

export const Pagina = ({
  prudoctPerPage,
  currentPage,
  setCurrentPage,
  totalProduct,
}) => {
  const pageNumber = [];
  const totalPages = Math.ceil(totalProduct / prudoctPerPage);

  for (let i = 1; i <= Math.ceil(totalProduct / prudoctPerPage); i++) {
    pageNumber.push(i);
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1);
    }
  }, [currentPage, totalPages, setCurrentPage]);

  const onPreviusPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onSpecificPage = (n) => {
    if (n <= totalPages) {
      setCurrentPage(n);
    }
  };

  return (
    <nav aria-label="pagination">
      <Pagination
        className="pagination justify-content-center justify-content-md-end mb-0"
        listClassName="justify-content-center justify-content-md-end mb-0"
      >
        <PaginationItem className={`${currentPage === 1 ? "disabled" : ""}`}>
          <PaginationLink
            onClick={onPreviusPage}
            style={{ color: "white", backgroundColor: "#0A166F" }}
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
        {pageNumber.map((nopage) => (
          <PaginationItem
            key={nopage}
            style={{
              display: nopage > totalPages ? "none" : "block",
            }}
            className={`${nopage === currentPage ? "active" : ""}`}
          >
            <PaginationLink
              onClick={() => onSpecificPage(nopage)}
              style={{
                opacity: nopage > totalPages ? 0.5 : 1,
                pointerEvents: nopage > totalPages ? "none" : "auto",
                color: "white",
                backgroundColor: "#0A166F",
              }}
            >
              {nopage}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem
          className={`${currentPage >= totalPages ? "disabled" : ""}`}
        >
          <PaginationLink
            onClick={onNextPage}
            style={{ color: "white", backgroundColor: "#0A166F" }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </nav>
  );
};
