import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import routes from "routes.js";
import img from "../assets/img/brand/logo_blanco.png";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const AzulOscuro = "#010027";
  const AzulClaro = "#103AB1";

  React.useEffect(() => {
    // Set the background color to Azul Oscuro when the component mounts
    document.body.style.backgroundColor = AzulOscuro;
    return () => {
      // Reset the background color when the component unmounts
      document.body.style.backgroundColor = "";
    };
  }, []);

  React.useEffect(() => {
    // Scroll to the top when location changes
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainContent.current) {
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div
      className="main-content"
      ref={mainContent}
      style={{ overflow: "hidden" }}
    >
      <div
        className="header py-7 py-lg-8"
        style={{
          background: `linear-gradient(100deg, ${AzulClaro} 0%, ${AzulOscuro} 100%)`,
        }}
      >
        <div className="header-body text-center mb-6">
          <Row className="justify-content-center text-center">
            <Col lg="5" md="6">
              <img
                style={{
                  width: "50%",
                  height: "auto",
                  display: "block",
                  margin: "auto",
                }}
                src={img}
                alt="HTlogo"
              />
            </Col>
          </Row>
        </div>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="fill-default"
              points="2560 0 2560 100 0 100"
              style={{ fill: AzulOscuro }}
            />
          </svg>
        </div>
      </div>

      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/auth/login" />
          </Switch>
        </Row>
      </Container>
    </div>
  );
};

export default Auth;
