import Login from "views/examples/Login.js";

import Actividad from "views/actividadServicio/Actividad";
import React from "react";
import Recovery from "views/recovery/Recovery";
import NewPassword from "views/newpassword/NewPassword";
import {
  IoClipboardOutline,
  IoGridOutline,
  IoSettingsOutline,
  IoCalendarClearOutline,
  IoAlbumsOutline,
  IoSwapHorizontal,
  IoArchiveOutline,
  IoCreateOutline,
} from "react-icons/io5";

import { CiUser, CiGlobe } from "react-icons/ci";
import { TbRulerMeasure } from "react-icons/tb";
import { GrResources, GrGroup, GrMoney } from "react-icons/gr";
import { BsBuildingGear, BsHouseDoor } from "react-icons/bs";
import { RiBillLine } from "react-icons/ri";
import { PiMoneyWavy, PiNewspaper } from "react-icons/pi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FiUsers, FiCopy } from "react-icons/fi";
import { AiOutlineControl } from "react-icons/ai";
const Unidade = React.lazy(() => import("views/unidad/Unidad"));
const parameters = React.lazy(() => import("views/parametros/Parametros"));
const Recursode = React.lazy(() => import("views/recurso/Recurso"));
const periodo = React.lazy(() => import("views/periodo/Periodo"));
const Servicide = React.lazy(() => import("views/servicios/Servicio"));
const ClaseSErvice = React.lazy(() =>
  import("views/claseservicios/ClaseServicio")
);
const Facturacion = React.lazy(() => import("views/facturacion/Facturacion"));
const Taorcora = React.lazy(() => import("views/taorcora/Taorcora"));
const Movimientos = React.lazy(() => import("components/Headers/HeaderClean"));
// const Balances = React.lazy(() => import("views/balance/Balance"));
const Indexado = React.lazy(() => import("views/Index.js"));
const Niteros = React.lazy(() => import("views/niteros/niteros"));
const Niterogroup = React.lazy(() => import("views/niterogroup/Niterogroup"));
const Resumen = React.lazy(() => import("views/resumen"));
const GroupClient = React.lazy(() => import("views/group_client/group_client"));
const ConceptoOrden = React.lazy(() =>
  import("views/Concepto-orden/Concepto-orden")
);
const ORDENES = React.lazy(() => import("views/ORDEN/orden"));
const Concepto1 = React.lazy(() => import("views/CONCORDE/concepto.js"));
const Usuario = React.lazy(() => import("views/usuario/Usuario"));
const NotFound = React.lazy(() => import("views/not found/Not found"));

var routes = [
  {
    category: "Inicio",
    categoryIcon: <BsHouseDoor />,
    path: "/index",
    name: "Dashboard",
    icon: <IoGridOutline />,
    component: Indexado,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "administrator", "coordinator"],
  },
  {
    path: "/notfound",
    layout: "/admin",
    component: NotFound,
    private: true,
    roles: ["superadmin", "developer", "administrator", "coordinator"],
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    roles: [],
  },
  {
    category: "Coordinadores",
    categoryIcon: <IoClipboardOutline />,
    path: "/cliente",
    name: "Clientes",
    icon: <FiUsers />,
    component: Niteros,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Coordinadores",
    path: "/GroupClient",
    name: "Creacion grupo de clientes",
    icon: <IoCreateOutline />,
    component: GroupClient,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Coordinadores",
    path: "/niteros-x-group-client",
    name: "Cliente por Grupo de Clientes",
    icon: <IoArchiveOutline />,
    component: Niterogroup,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Coordinadores",
    path: "/unidad",
    name: "Unidad",
    icon: <TbRulerMeasure />,
    component: Unidade,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Coordinadores",
    path: "/recurso",
    name: "Recurso",
    icon: <GrResources />,
    component: Recursode,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Coordinadores",
    path: "/periodo",
    name: "Periodo",
    icon: <IoCalendarClearOutline />,
    component: periodo,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Coordinadores",
    path: "/claseservicio",
    name: "Clases de servicios",
    icon: <GrGroup />,
    component: ClaseSErvice,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Coordinadores",
    path: "/servicio",
    name: "Servicio",
    icon: <BsBuildingGear />,
    component: Servicide,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Coordinadores",
    path: "/concepto-Item",
    name: "Concepto o Ítem",
    icon: <GrMoney />,
    component: Concepto1,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Coordinadores",
    categoryIcon: <IoClipboardOutline />,
    path: "/orden",
    name: "Orden",
    icon: <RiBillLine />,
    component: ORDENES,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator", "administrator"],
  },
  {
    category: "Coordinadores",
    path: "/conceptoorden",
    name: "Concepto o item por Orden",
    icon: <IoAlbumsOutline />,
    component: ConceptoOrden,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Coordinadores",
    path: "/taorcora",
    name: "Tarifa",
    icon: <PiMoneyWavy />,
    component: Taorcora,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Coordinadores",
    path: "/movimientos",
    name: "Movimientos",
    icon: <IoSwapHorizontal />,
    component: Movimientos,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  // {
  //   category: "Coordinadores",
  //   path: "/balances",
  //   name: "Balances",
  //   icon: <LiaBalanceScaleSolid />,
  //   component: Balances,
  //   layout: "/admin",
  //   private: true,
  //   roles: ["superadmin", "developer", "coordinator"],
  // },

  {
    category: "Coordinadores",
    path: "/actividad",
    name: "Actividad x Servicio",
    icon: <FiCopy />,
    component: Actividad,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "coordinator"],
  },
  {
    category: "Administrativo",
    categoryIcon: <IoSettingsOutline />,
    path: "/facturacion",
    name: "Facturación",
    icon: <LiaFileInvoiceDollarSolid />,
    component: Facturacion,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "administrator"],
  },
  {
    path: "/recovery",
    name: "Recovery",
    icon: "ni ni-circle-08 text-pink",
    component: Recovery,
    layout: "/auth",
    roles: [],
  },
  {
    path: "/newpassword",
    name: "NewPassword",
    icon: "ni ni-circle-08 text-red",
    component: NewPassword,
    layout: "/auth",
    roles: [],
  },

  {
    category: "Administrativo",
    categoryIcon: <IoSettingsOutline />,
    path: "/resumen",
    name: "Resumen",
    icon: <PiNewspaper />,
    component: Resumen,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer", "administrator", "coordinator"],
  },
  {
    category: "Panel de control",
    categoryIcon: <AiOutlineControl />,
    path: "/usuario",
    name: "Usuarios",
    icon: <CiUser />,
    component: Usuario,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer"],
  },
  {
    category: "Panel de control",
    path: "/parameters",
    name: "Parametros",
    icon: <CiGlobe />,
    component: parameters,
    layout: "/admin",
    private: true,
    roles: ["superadmin", "developer"],
  },
];

export default routes;

// {
//   path: "/roles",
//   name: "Roles",
//   icon: "ni ni-camera-compact text-yellow",
//   component: Roles,
//   layout: "/admin",
//
// },
// {
//   path: "/funcionalidad",
//   name: "Funcionalidad",
//   icon: "ni ni-folder-17 text-blue",
//   component: Funcionalidad,
//   layout: "/admin",
//
// },
// {
//   path: "/rolesxusuario",
//   name: "Roles x Usuario",
//   icon: "ni ni-laptop  text-yellow",
//   component: RolesxUsuario,
//   layout: "/admin",
//
// },
// {
//   path: "/funcinalxrol",
//   name: "Funcionalidad x Rol",
//   icon: "ni ni-basket text-red",
//   component: FuncionalxRol,
//   layout: "/admin",
//
// },
